<template>
  <div>
    <div class="d-flex align-items-center mb-1">
      <a @click="$router.go(-1)" class="grey-color">
        <div class="d-flex align-items-center mr-2">
          <feather-icon icon="ArrowLeftIcon" size="20" class="mr-25" />
          <h6 class="mb-0">Назад</h6>
        </div>
      </a>
    </div>
    <h1 class="mb-2">Редактирование слайдера</h1>
    <component :is="getSlider === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert variant="danger" :show="getSlider === undefined">
        <h4 class="alert-heading">
          Ошибка при получении данных этого слайдера
        </h4>
        <div class="alert-body">
          Слайдеров с таким айди не существует. Проверьте
          <b-link class="alert-link" :to="{ name: 'sliders' }">
            Список Слайдеров
          </b-link>
          для других слайдеров.
        </div>
      </b-alert>

      <slider-edit-tab-info class="" />

      <b-tabs v-if="getSlider" pills>
        <!-- Tab: Account -->
        <!-- <b-tab active>
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Информация</span>
          </template>
          
        </b-tab> -->

        <!-- Tab: Social -->
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Филиалы</span>
          </template>
          <merchant-edit-tab-services />
        </b-tab> -->

        <!-- Tab: Services -->
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="ShoppingCartIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Товары/услуги</span>
          </template>
          <merchant-edit-tab-services />
        </b-tab> -->

        <!-- Tab: Promocodes -->
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Купоны/сертификаты</span>
          </template>
          <merchant-edit-tab-services />
        </b-tab> -->
      </b-tabs>
    </component>
  </div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import SliderEditTabInfo from "./SliderEditTabInfo.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    SliderEditTabInfo,
  },
  computed: {
    ...mapGetters(["getSlider"]),
  },
  watch: {
    getSlider() {
      this.$store.dispatch("fetchOffer", this.getSlider.entityId);
      this.$store.dispatch("fetchMerchant", this.getSlider.vendorId);
    },
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("fetchSlider", this.$router.currentRoute.params.id);
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
