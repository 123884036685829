<template>
  <div>
    <b-row>
      <!-- Field: Full Name -->
      <b-col cols="12" md="12">
        <h4 class="mb-2">
          {{ getSlider.name }}
        </h4>
      </b-col>
      <b-col cols="12" md="12" class="mb-1">
        <span class="sm-size"
          >(Поддерживаются файлы в формате .jpg, .png, .tiff)
        </span>
      </b-col>
      <b-col cols="12" md="2">
        <b-media class="mb-2">
          <div class="d-flex flex-column">
            <label for="">Изображение Ru</label>
            <div class="slider_img"></div>
            <!--  <b-img :src="getSlider.imageUrlRu" fluid class="slider_img mb-2" /> -->

            <b-avatar
              :src="getSlider.imageUrlRu"
              :variant="`light-primary`"
              size="100%"
              class="mb-1"
              rounded
            />
            <div class="d-flex flex-column">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=""
                @click="$refs.refInputElRu.click()"
              >
                <input
                  ref="refInputElRu"
                  type="file"
                  accept="image/png, image/jpeg, image/tiff"
                  class="d-none"
                  @change="handleFileUploadRu($event)"
                />
                <span class="d-none d-sm-inline">Загрузить</span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
              </b-button>
              <div>
                <small class="text-danger" v-if="imageRequiredRu === 1"
                  >Поле Лого поддерживает только файлы в формате .jpg, .png,
                  .tiff</small
                >
              </div>
            </div>
          </div>
          <!-- d-flex -->
        </b-media>
      </b-col>

      <b-col cols="12" md="2">
        <b-media class="mb-2">
          <div class="d-flex flex-column">
            <label for="">Изображение Uz</label>
            <div class="slider_img"></div>
            <!--  <b-img :src="getSlider.imageUrlRu" fluid class="slider_img mb-2" /> -->

            <b-avatar
              :src="getSlider.imageUrlUz"
              :variant="`light-primary`"
              size="100%"
              class="mb-1"
              rounded
            />
            <div class="d-flex flex-column">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=""
                @click="$refs.refInputElUz.click()"
              >
                <input
                  ref="refInputElUz"
                  type="file"
                  accept="image/png, image/jpeg, image/tiff"
                  class="d-none"
                  @change="handleFileUploadUz($event)"
                />
                <span class="d-none d-sm-inline">Загрузить</span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
              </b-button>
              <div>
                <small class="text-danger" v-if="imageRequiredUz === 1"
                  >Поле Лого поддерживает только файлы в формате .jpg, .png,
                  .tiff</small
                >
              </div>
            </div>
          </div>
          <!-- d-flex -->
        </b-media>
      </b-col>

      <b-col cols="12" md="2">
        <b-media class="mb-2">
          <div class="d-flex flex-column">
            <label for="">Изображение En</label>
            <div class="slider_img"></div>
            <!--  <b-img :src="getSlider.imageUrlRu" fluid class="slider_img mb-2" /> -->

            <b-avatar
              :src="getSlider.imageUrlEn"
              :variant="`light-primary`"
              size="100%"
              class="mb-1"
              rounded
            />
            <div class="d-flex flex-column">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=""
                @click="$refs.refInputElEn.click()"
              >
                <input
                  ref="refInputElEn"
                  type="file"
                  accept="image/png, image/jpeg, image/tiff"
                  class="d-none"
                  @change="handleFileUploadEn($event)"
                />
                <span class="d-none d-sm-inline">Загрузить</span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
              </b-button>
              <div>
                <small class="text-danger" v-if="imageRequiredEn === 1"
                  >Поле Лого поддерживает только файлы в формате .jpg, .png,
                  .tiff</small
                >
              </div>
            </div>
          </div>
          <!-- d-flex -->
        </b-media>
      </b-col>
    </b-row>
    <validation-observer ref="simpleRules">
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: Full Name -->

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Название"
              rules="required"
            >
              <b-form-group label="Название" label-for="slider-name">
                <b-form-input
                  id="slider-name"
                  :state="errors.length > 0 ? false : null"
                  v-model="getSlider.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Ссылка"
              rules="required|url"
            >
              <b-form-group label="Ссылка" label-for="slider-siteUrl">
                <b-form-input
                  id="slider-siteUrl"
                  :state="errors.length > 0 ? false : null"
                  v-model="getSlider.siteUrl"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Статус"
              rules="required"
            >
              <b-form-group label="Статус" label-for="slider-status">
                <v-select
                  v-model="getSlider.state"
                  :reduce="(status) => status.value"
                  :options="statusOptions"
                  :state="errors.length > 0 ? false : null"
                  label="title"
                  :clearable="false"
                  input-id="slider-status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: textColor -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Цвет текста"
              rules="required"
            >
              <b-form-group label="Цвет текста" label-for="slider-textColor">
                <v-select
                  v-model="getSlider.textColor"
                  :reduce="(status) => status.value"
                  :options="textColors"
                  label="title"
                  :state="errors.length > 0 ? false : null"
                  :clearable="false"
                  input-id="slider-textColor"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: intentType -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Тип слайдера"
              rules="required"
            >
              <b-form-group label="Тип слайдера" label-for="slider-intentType">
                <v-select
                  v-model="getSlider.sliderIntentType"
                  :reduce="(status) => status.value"
                  :options="intentTypes"
                  label="title"
                  :state="errors.length > 0 ? false : null"
                  :clearable="false"
                  input-id="slider-intentType"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: isClickable -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Кликабельность"
              rules="required"
            >
              <b-form-group
                label="Кликабельность"
                label-for="slider-isClickable"
              >
                <v-select
                  v-model="getSlider.isClickable"
                  :reduce="(status) => status.value"
                  :options="clickableOptions"
                  label="title"
                  :state="errors.length > 0 ? false : null"
                  :clearable="false"
                  input-id="slider-isClickable"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>


          <!-- Field: offerMerchant -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Мерчант"
              rules="required"
            >
              <b-form-group label="Мерчант" label-for="slider-merchant">
                <v-select
                  id="slider-merchant"
                  placeholder="Выберите мерчант"
                  v-model="selectedMerchant"
                  :options="sortedMerchants"
                  :state="errors.length > 0 ? false : null"
                  @search="getMerchants"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: offerMerchant -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Мерчант"
              rules="required"
            >
              <b-form-group label="Предложение" label-for="slider-offer">
                <v-select
                  id="slider-offer"
                  placeholder="Выберите предложение"
                  v-model="selectedOffer"
                  :options="sortedOffers"
                  :state="errors.length > 0 ? false : null"
                  @search="getoffers"
                  label="titleRu"
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12">
            <!-- Action Buttons -->
            <b-button
              @click.prevent="handleSubmit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password, url } from "@validations";

export default {
  components: {
    ToastificationContent,
    BBadge,
    BFormTextarea,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {},
  watch: {
    getMerchant(val) {
      this.selectedMerchant = val;
    },
    getOffer(val) {
      this.selectedOffer = val;
    },
  },
  computed: {
    ...mapGetters(["getSlider", "getMerchant", "getOffer"]),
    statusVariant() {
      const statusVariants = {
        /* eslint-disable key-spacing */
        ACTIVE: {
          title: "Активный",
          value: "ACTIVE",
        },
        DELETED: {
          title: "Удаленный",
          value: "DELETED",
        },
        /* eslint-enable key-spacing */
      };

      return (status) => statusVariants[status];
    },
  },
  setup() {
    return {
      avatarText,
    };
  },
  data() {
    return {
      required,
      email,
      password,
      confirmed,
      url,

      file: null,
      fileUrl: "",
      categories: null,
      logo: null,
      sliderVendor: null,

      imageRequiredRu: 0,
      imageRequiredUz: 0,
      imageRequiredEn: 0,

      sortedMerchants: [],
      selectedMerchant: null,

      sortedOffers: [],
      selectedOffer: null,

      imgRu: null,
      imgUz: null,
      imgEn: null,

      statusOptions: [
        {
          title: "Активный",
          value: "ACTIVE",
        },
        {
          title: "Удаленный",
          value: "DELETED",
        },
      ],

      textColors: [
        {
          title: "Темный",
          value: "DARK",
        },
        {
          title: "Светлый",
          value: "LIGHT",
        },
      ],
      intentTypes: [
        {
          title: "Предложение",
          value: "OFFER",
        },
        {
          title: "Ничего",
          value: "NONE",
        },
      ],
      clickableOptions: [
        {
          title: "Некликабельный",
          value: 0,
        },
        {
          title: "Кликабельный",
          value: 1,
        },
      ],

      useInsideAppOptions: [
        {
          title: "Не использовать",
          value: 0,
        },
        {
          title: "Использовать",
          value: 1,
        },
      ],
    };
  },
  mounted() {
    this.getOffers();
    this.getMerchants();
  },
  methods: {
    async handleFileUploadRu(event) {
      this.imgRu = event.target.files[0];
      if (
        this.imgRu.type != "image/jpg" &&
        this.imgRu.type != "image/jpeg" &&
        this.imgRu.type != "image/png" &&
        this.imgRu.type != "image/tiff"
      ) {
        this.imageRequiredRu = 1;
        return false;
      }
      this.imageRequiredRu = 2;
      const formData = new FormData();
      formData.append("logo", this.imgRu);
      const response = await axiosIns.post("image/upload/slider", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.getSlider.imageUrlRu = response.data.data.imageUrl;
    },

    async handleFileUploadUz(event) {
      this.imgUz = event.target.files[0];
      if (
        this.imgUz.type != "image/jpg" &&
        this.imgUz.type != "image/jpeg" &&
        this.imgUz.type != "image/png" &&
        this.imgUz.type != "image/tiff"
      ) {
        this.imageRequiredUz = 1;
        return false;
      }
      this.imageRequiredUz = 2;
      const formData = new FormData();
      formData.append("logo", this.imgUz);
      const response = await axiosIns.post("image/upload/slider", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.getSlider.imageUrlUz = response.data.data.imageUrl;
    },

    async handleFileUploadEn(event) {
      this.imgEn = event.target.files[0];
      if (
        this.imgEn.type != "image/jpg" &&
        this.imgEn.type != "image/jpeg" &&
        this.imgEn.type != "image/png" &&
        this.imgEn.type != "image/tiff"
      ) {
        this.imageRequiredEn = 1;
        return false;
      }
      this.imageRequiredEn = 2;
      const formData = new FormData();
      formData.append("logo", this.imgEn);
      const response = await axiosIns.post("image/upload/slider", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.getSlider.imageUrlEn = response.data.data.imageUrl;
    },

    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axiosIns
            .put("v1/slider/edit", {
              id: this.getSlider.id,
              urlUz: this.getSlider.imageUrlUz,
              urlRu: this.getSlider.imageUrlRu,
              urlEn: this.getSlider.imageUrlEn,
              name: this.getSlider.name,
              state: this.getSlider.state,
              isClickable: this.getSlider.isClickable,
              entityId: this.selectedOffer.id,
              siteUrl: this.getSlider.siteUrl,
              textColor: this.getSlider.textColor,
              intentType: this.getSlider.sliderIntentType,
              vendorId: this.selectedMerchant.id
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Слайдер был успешно изменен!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.$router.push({ name: "sliders" });
            });
        }
      });
    },
    async deleteSlider() {
      this.$bvModal
        .msgBoxConfirm("Вы уверены что хотите удалить?", {
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: "Удалить",
          cancelTitle: "Отмена",
        })
        .then((value) => {
          if (value === true) {
            axiosIns
              .delete("v1/city/delete", {
                params: {
                  cityId: this.$router.currentRoute.params.id,
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: `Успешно`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Город был успешно удален!`,
                      },
                    },
                    {
                      position: "top-right",
                    }
                  );
                  this.getData();
                }
              })
              .catch((error) => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Ошибка`,
                      icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                      variant: "danger",
                      text: error.response.data.errorMessage,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
              })
              .finally(() => {
                this.$router.push({ name: "slider" });
              });
          }
        });
    },
    async getMerchants(search) {
      const response = await axiosIns
        .get("vendor/all-active", {
          params: {
            page: 1,
            size: 20,
            field: "name",
            fieldValue: search,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.sortedMerchants = response.data.data.vendors;
    },

    async getOffers(search) {
      const response = await axiosIns
        .get("offer/getAll", {
          params: {
            page: 1,
            size: 20,
            field: "titleRu",
            fieldValue: search,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.sortedOffers = response.data.data.offers;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
